<template>
	<div class="HSMask">
		<div class="HSDialog">
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">修改积分</div>
				<img class="HSDialogCloseImg" src="../../../assets/img/HS/close.png" @click="close"/>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<el-form style="padding:20px 30px;box-sizing: border-box;" label-width="70px">
				<el-row>
					<el-col :span="24">
						<el-form-item label="当前积分" >
							<el-input :value="userInfo.cardInfo.userJifen" disabled></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row >
					<el-col :span="24">
						<el-form-item label="修改为" required>
							<el-input v-model="money" @input="inputMoney"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row >
					<el-col :span="24">
						<el-form-item label="备注">
							<el-input type="textarea" :rows="3" v-model="remark"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div style="height: 80px;"></div>
			<div class="HSDialogButtonLine">
				<div class="HSDialogButtonCancel" @click="close">取消</div>
				<div class="HSDialogButtonConfirm" @click="submit">确认</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				money:'',
				remark:'',
			}
		},
		computed:{
			
		},
		props:{
			userInfo:{
				default:()=>{
					return {}
				}
			},
		},
		mounted(){
			
		},
		methods:{
			// 关闭
			close(){
				this.$emit("submit",{type:"close",data:""})
			},
			// 提交
			submit(){
				if(this.$public.isNull(this.money)){
					this.$message({
						type: 'error',
						message: '请输入修改后积分！'
					});
					return
				}
				let params = {
					memberId:this.userInfo.id,
					jifen:Number(this.money),
					remark:this.remark,
				}
				let loading = this.$loading()
				this.$http.post("/customer/Member/ChangeScore",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.$message({
							type: 'success',
							message: '操作成功'
						});
						this.$emit("submit",{type:"success",data:""})
					}
				})
			},
			inputMoney(){
				this.money = this.$public.replaceToDouble(this.money)
			},
		}
	};
</script>
<style lang="scss" scoped>
	
</style>
