<template>
	<div class="page">
		<div class="title">
			<div class="titleL">会员积分</div>
			<div class="titleR">
				<el-button type="infor" @click="$router.back()">返回</el-button>
			</div>
		</div>
		<div class="content">
			<!-- 搜索栏 -->
			<div class="tabView">
				<div class="tabViewB">
					<div class="search">
						<div class="searchTitle">类型</div>
						<el-select v-model="consumeType" @change="changeConsumeType" placeholder="请选择" style="width: 143px;">
							<el-option v-for="(item,index) in [
								{label:'全部',value:0},
								{label:'充值',value:1},
								{label:'消费',value:2},
								{label:'退货',value:3},
								{label:'换货',value:4},
								{label:'修改',value:5},
								{label:'退款',value:6}
							]" :key="index" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="search">
						<div class="searchTitle">日期</div>
						<el-date-picker
							v-model="timeRange"
							@change="changeTimeRange"
							type="daterange"
							start-placeholder="开始时间"
							end-placeholder="结束时间"
						>
						</el-date-picker>
					</div>
					<div class="tabViewBR">
						<el-button type="primary" v-if="$buttonAuthority('huiyuan-huiyuanList-see-editPoints')" @click="dialogEditJifen_state=true">修改积分</el-button>
					</div>
				</div>
			</div>
			<!-- 表格 -->
			<div class="tableBox">
				<el-table :data="jifenRecordList" style="width: 100%" height="100%">
					<el-table-column prop="date" label="日期">
						<template slot-scope="scope">
							{{scope.row.operationInfo.create.handleTime}}
						</template>
					</el-table-column>
					<el-table-column prop="name" label="关联单号">
						<template slot-scope="scope">
							{{scope.row.orderNum}}
						</template>
					</el-table-column>
					<el-table-column prop="province" label="类型">
						<template slot-scope="scope">
							<span v-if="scope.row.consumeType===1">充值</span>
							<span v-if="scope.row.consumeType===2">消费</span>
							<span v-if="scope.row.consumeType===3">退货</span>
							<span v-if="scope.row.consumeType===4">换货</span>
							<span v-if="scope.row.consumeType===5">修改</span>
							<span v-if="scope.row.consumeType===6">退款</span>
						</template>
					</el-table-column>
					<el-table-column prop="city" label="变化值">
						<template slot-scope="scope">
							{{$public.jian(scope.row.after.userJifen,scope.row.before.userJifen)}}
						</template>
					</el-table-column>
					<el-table-column prop="address" label="变动后积分">
						<template slot-scope="scope">
							{{scope.row.after.userJifen}}
						</template>
					</el-table-column>
					<el-table-column prop="address" label="备注">
						<template slot-scope="scope">
							{{scope.row.remark}}
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div style="width:100%;position: absolute;bottom: 0;right: 0;">
				<page :pageSizes="pageSizes"	@size-change="handleSizeChange" :pageIndex="page.pageIndex" :pageSize="page.pageSize" :total="page.total" @change="changePage"></page>
			</div>
		</div>
		<div v-if="dialogEditJifen_state">
			<dialogEditJifen :userInfo="userDetail" @submit="submit_dialogEditJifen"></dialogEditJifen>
		</div>
	</div>
</template>
<script>
	import dialogEditJifen from "./dialogEditJifen.vue"
	import page from "../../../components/page.vue"
	export default {
		components: {dialogEditJifen,page},
		data() {
			return {
				userDetail:{
					id:'',
					basicInfo:{},
					cardInfo:{},
					companyInfo:{},
					status:{},
					vipGrades:{},
					pets_info:[],
				},
				// 积分
				consumeType:0,
				timeRange:'',
				page:{
					pageIndex:1,
					pageSize:10,
					total:0,
				},
				pageSizes: [10, 15,30,60],
				jifenRecordList:[],
				// 修改积分
				dialogEditJifen_state:false,
			}
		},
		computed:{
			
		},
		mounted() {
			this.getDetail()
			this.getJifenRecordList()
		},
		methods: {
			// 获取用户详情
			getDetail(){
				let params = {
					id:this.$route.query.id
				}
				let loading = this.$loading()
				this.$http.get("/customer/Member/Get",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.userDetail = res.data
					}
				})
			},
			// 积分记录
			changeConsumeType(){
				this.page.pageIndex=1;
				this.getJifenRecordList();
			},
			changeTimeRange(){
				this.page.pageIndex=1;
				this.getJifenRecordList();
			},
			changePage(num){
				this.page.pageIndex = num;
				this.getJifenRecordList()
			},
			handleSizeChange(val) {
			this.page.pageIndex = 1;
			this.page.pageSize = val
			this.getJifenRecordList()
		},
			getJifenRecordList(){
				let startTime='';
				let endTime='';
				if(this.timeRange&&this.timeRange.length==2){
					startTime = this.$public.FTimeStr10(this.timeRange[0])
					endTime = this.$public.FTimeStr10(this.timeRange[1])
				}
				let params = {
					memberId:this.$route.query.id,
					"consumeType": this.consumeType,  // 0:全部、1:充值、2:消费、3:退货、4:换货、5:修改
					"type": 2,  // -1.全部1.余额 2.积分
					"startTime": startTime,
					"endTime": endTime,
					"keyword": "",
					"pageIndex": this.page.pageIndex,
					"pageSize": this.page.pageSize,
				}
				let loading = this.$loading()
				this.$http.post("/customer/Member/PropertyRecordList",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.jifenRecordList = res.data.list
						this.page.total = res.data.count
					}
				})
			},
			// 修改积分
			submit_dialogEditJifen(obj){
				this.dialogEditJifen_state=false
				if(obj.type==='success'){
					this.getJifenRecordList()
				}
			},
		}
	}
</script>
<style lang="scss" scoped>
	.page {
		width:100%;height: 100%;border-radius: 20px;overflow: hidden;color: $fontColor;background-color: #fff;
		.title{
			display: flex;justify-content: space-between;border-bottom: 1px solid #f2f2f2;height: 68px;box-sizing: border-box;padding:14px 20px;
			.titleL{
				font-size: 16px;color:$fontColor;line-height: 40px;
			}
			.titleR{
				
			}
		}
		.tit{
			padding:32px 20px 8px;display: flex;justify-content: space-between;
			.titL{
				font-size: 16px;color:$fontColor;background: url(../../../assets/img/cangku/detailName.png) 2px 8px no-repeat;background-size: 24px 24px;
				.titText{
					padding-left:12px;display:inline-block;line-height: 40px;
				}
				.editImg{
					width:20px;height:20px;margin-left:8px;vertical-align: middle;cursor: pointer;
				}
			}
		}
		.msgBox{
			margin:0 20px;background-color: #F7F7F7;padding:10px 30px;box-sizing: border-box;border-radius: 20px;
			.msgItem{
				font-size: 14px;color:$fontColor;line-height: 40px;
				.editImg{
					width:20px;height:20px;margin-left:8px;vertical-align: middle;cursor: pointer;
				}
			}
		}
		.content{
			width:100%;height: calc(100% - 67px);padding-bottom:52px;box-sizing: border-box;position: relative;
			// 搜索信息
			.tabView {
				height: 80px;
				border-radius: 20px;
				background: #fff;
				display: flex;
				flex-direction: column;
			
				.tabViewT {
					height: 80px;
					border-bottom: solid 1px #F2F2F2;
					display: flex;
					align-items: center;
			
					.tabItem {
						width: 116px;
						line-height: 40px;
						text-align: center;
						font-size: 16px;
						border-radius: 40px;
						margin-left: 20px;
						cursor: pointer;
						background: #FFF0E0;
					}
			
					.active {
						background: #F77E04;
						color: #fff;
					}
				}
			
				.tabViewB {
					
					flex: 1;
					display: flex;
					align-items: center;
					padding: 0 20px;
			
					.search {
						display: flex;
						align-items: center;
						margin-right: 32px;
			
						.searchTitle {
							font-size: 14px;
							padding-right: 4px;
							color: $fontColor;
						}
					}
			
					.tabViewBR {
						flex: 1;
						display: flex;
						align-items: center;
						justify-content: flex-end;
					}
				}
			}
			.tableBox{
				height: calc(100% - 80px);
			}
		}
	}
</style>
